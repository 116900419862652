//DEFAULTS
import "core-js/stable";
import "regenerator-runtime/runtime";

import site from 'site/site'
import global from 'site/global'
import siteScroll from 'site/scroll'
import mobileNav from 'site/mobileNav'
import siteAOS from 'site/site.aos'
import featuredPanel from 'site/featuredPanel'
import quickThumbsPrep from 'site/quickThumbsPrep'
import oneId from 'site/oneId'
import showtimes from 'site/showtimes'
import overlay from 'site/overlay'
import films from 'site/films'

import mediaOverlay from 'site/mediaOverlay'

$(document).ready(function() {
    site.init()
    global.init()
    films.init() // before mobile nav
    mobileNav.init()
    siteScroll.init()
    featuredPanel.init()
    quickThumbsPrep.init()
    showtimes.init()
    overlay.init()
    mediaOverlay.init()
    // LAST
    oneId.init()
    setTimeout( function() {
        siteAOS.init()
        siteAOS.refreshAOS()
    },500)

})
