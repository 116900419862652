//import $ from 'jquery'
import Swiper from 'swiper'
import { Navigation, Autoplay } from 'swiper/modules'

import { gsap, CSSPlugin, ScrollToPlugin } from 'gsap/all'
import { Power4 } from 'gsap'
gsap.registerPlugin(ScrollToPlugin);
let global = {
    init: function () {
        $('.jump').on('click',global.jump)

        global.refreshGtagLinks()

        global.globalSwiper()
    },
    ////////////////////////////////////////////////////////////////////////////////////

    globalSwiper() {
        //
        this.swiper = new Swiper("#header-alert-swiper", {
            speed: 500,
            autoplay: {
                delay: 8000,
                disableOnInteraction: false,
            },
            effect: 'slide',
            modules: [Navigation, Autoplay],
            init: true,
            loop: true,
            initialSlide: 0,
            // loopedSlides: 10,
            spaceBetween: 0,
            slidesPerView: 1,
            slidesPerGroup: 1,
            slideToClickedSlide: false,
            centeredSlides: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            touchMoveStopPropagation: true,
            mousewheelControl: false,
            navigation: {
                nextEl: "#header-alert-swiper .swiper-button-next",
                prevEl: "#header-alert-swiper .swiper-button-prev",
            },
            //pagination: {
            //    enabled:true,
            //    clickable: true,
            //    el: this.sel + "-dots .dots",
            //}
        })
    },

    jump: function() {
        let tid = '#' + $(this).attr('data-jump')
        gsap.to(window, {duration: 0.75, ease: Power4.easeInOut, scrollTo: {y: tid, offsetY: 70}});
    },

    refreshGtagLinks() {
        $('.gtag-hook').off('click')
        $('.gtag-hook').click(function() {
            // Retrieve category data from the link's data attributes
            let cat1 = $(this).data('cat1');  // main category
            let cat2 = $(this).data('cat2');  // secondary category
            let cat3 = $(this).data('cat3');  // third category

            // Send the event to Google Analytics
            gtag('event', cat2, {
                'event_category': cat1,
                'event_label': cat1 + ' - ' + cat3,
                'non_interaction': true
            })
            // console.log('gtag-hook', cat1, cat2, cat3)
        })
    }
}
export default global
