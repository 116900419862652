// core version + navigation, pagination modules:
import siteCommon from 'site/site-common'
import browser from 'browser-detect'
let featuredPanel = {
    browser:browser(),
    currentIndex: 0,
    numberPanels: 0,
    isHovering: 0,

    touchStartY: 0,
    touchEndY: 0,

    lastScrollTop: 0,
    debounceTimer: null,

    mode: '',

    reachedEnd: false,
    readyToScroll: true,
    readyToEndFixed: false,
    viewportHeight: 0,
    didFirstPosterSet: false,
    init: function () {
        if ($('body').hasClass('page-home')) {

            featuredPanel.numberPanels = $('#featured-panel .panels .panel').length

            // mobile
            $("#featured-panel").on("touchstart", function(event){
                if (featuredPanel.mode === 'tiny') {
                    featuredPanel.touchStartY = event.changedTouches[0].screenY
                }
            });

            $("#featured-panel").on("touchend", function(event){
                if (featuredPanel.mode === 'tiny') {
                    featuredPanel.touchEndY = event.changedTouches[0].screenY
                    featuredPanel.handleSwipe()
                }
            });
            $(window).on("mousewheel DOMMouseScroll", function(event) {
                if (featuredPanel.mode === 'tiny') {
                    // ready to scroll?
                    if (featuredPanel.readyToScroll === true) {
                        clearTimeout(featuredPanel.debounceTimer);
                        featuredPanel.debounceTimer = setTimeout(function () {
                            featuredPanel.readyToScroll = true
                        }, 750);

                        featuredPanel.readyToScroll = false
                        // For cross-browser compatibility, use `originalEvent`
                        let e = event.originalEvent;

                        // deltaY for modern browsers, wheelDelta for older ones
                        let delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
                        let scrollTop = $(window).scrollTop();

                        if (scrollTop <= 0){
                            if (delta < 0) {
                                // Scrolling up
                                featuredPanel.swipeVideoNext();
                            } else {
                                // Scrolling down
                                featuredPanel.swipeVideoPrev();
                            }
                        }


                        // Update the last scroll position
                        featuredPanel.lastScrollTop = $(window).scrollTop();

                    }
                }
            });
            $(window).on( "resize", featuredPanel.resize);

            // menu hovers for desktop
            $('#featured-panel .menu-hook').on('mouseenter', featuredPanel.mouseenter)
            $('#featured-panel .menu-hook').on('mouseleave', featuredPanel.mouseleave)

            // clicking dots
            $('#featured-panel .dot-hook').on('click', featuredPanel.dotClick)

            // set first
            if ($('body').hasClass('dm')) {
                //featuredPanel.videoAutoplay();
            } else {
                featuredPanel.videoSet()
            }

            //resize
            featuredPanel.resize()
        }

    },

    videoAutoplay() {
        //VIDEO AUTOPLAY
        if ($('body').hasClass('page-home')) {
            if (featuredPanel.getCookie('videoAutoplay')) {
                // EXISTS
                featuredPanel.videoAutoplayDone()
            } else {
                // NOT EXIST
                $('body').addClass('fixed-video')
                $('#specialVideo').css('opacity', 1)
                $('#videoOverlay').show();
                // Get the video element
                var video = document.getElementById('specialVideo');
                // Check if the video is already playing
                if (!video.paused) {
                    //console.log('Video is already playing');
                } else {
                    // Try to play the video
                    video.play().then(() => {
                        //console.log('Video playback started successfully');
                    }).catch(error => {
                        //console.error('Error attempting to play the video:', error);
                        featuredPanel.videoAutoplayDone()
                    });
                }

                // Add an event listener to log when the video is done playing
                video.addEventListener('ended', () => {
                    //console.log('done');
                    featuredPanel.videoAutoplayDone()
                });
            }
            featuredPanel.setCookie('videoAutoplay', 'true', 10); // set it to 10 minutes expire
        }
    },
    videoAutoplayDone() {
        $('body').removeClass('fixed-video')
        featuredPanel.videoSet();
        $('#videoOverlay').hide();
    },

    resize: function(s) {
        featuredPanel.mode = siteCommon.scGetSiteMode() //large, tiny
        // always loop mobile
        if (featuredPanel.mode === 'large') {
            featuredPanel.isHovering = false
        } else {
            featuredPanel.isHovering = true
        }

        if (featuredPanel.reachedEnd) {
            $('body').removeClass('fixed')
        } else {
            $('body').addClass('fixed')
        }

        featuredPanel.videoSet(false)


        if (featuredPanel.browser.mobile) {
            let viewportHeight = window.innerHeight;
            if (!featuredPanel.viewportHeight) {
                featuredPanel.viewportHeight = viewportHeight
                $('#featured-panel').css('height', featuredPanel.viewportHeight)
                $('#featured-panel').addClass('device')
            }
        }
    },
    handleSwipe: function() {
        //let scrollTop = $(window).scrollTop();
        //if (scrollTop <= 0) {
            if (featuredPanel.touchEndY < featuredPanel.touchStartY) {
                // SWIPE UP, SO GO DOWN
                featuredPanel.swipeVideoNext()
            }
            if (featuredPanel.touchEndY > featuredPanel.touchStartY) {
                // SWIPE DOWN, SO GO UP
                featuredPanel.swipeVideoPrev()
            }
        //}
    },
    dotClick: function() {
        featuredPanel.currentIndex = Number($(this).attr('data-index'))
        featuredPanel.videoSet()
    },
    mouseenter: function() {
        if (featuredPanel.mode === 'large') {
            featuredPanel.isHovering = true
            featuredPanel.currentIndex = Number($(this).attr('data-index'))
            featuredPanel.videoSet()
        }
    },
    mouseleave: function() {
        if (featuredPanel.mode === 'large') {
            featuredPanel.isHovering = false
        }
    },
    videoEnd: function() {
        if (!featuredPanel.isHovering) {
            featuredPanel.currentIndex++
            if (featuredPanel.currentIndex >= featuredPanel.numberPanels) {
                featuredPanel.currentIndex = 0
            }
        }
        featuredPanel.videoSet()
    },
    swipeVideoNext: function() {
        featuredPanel.currentIndex++
        featuredPanel.readyToEndFixed = false
        if (featuredPanel.currentIndex >= featuredPanel.numberPanels - 1) {
            featuredPanel.currentIndex = featuredPanel.numberPanels - 1
            featuredPanel.readyToEndFixed = true
        }
        featuredPanel.videoSet()
    },
    swipeVideoPrev: function() {
        featuredPanel.readyToEndFixed = false
        featuredPanel.currentIndex--
        if (featuredPanel.currentIndex <=0 ) {
            featuredPanel.currentIndex = 0
        }
        featuredPanel.videoSet()
    },
    videoSet: function(doResize=true) {
        $('#featured-panel .dot-hook').removeClass('active')
        $('#featured-panel .menu-hook').removeClass('active')
        $('#featured-panel .panel-hook').removeClass('active')
        $('#featured-panel .dot-hook-'+featuredPanel.currentIndex).addClass('active')
        $('#featured-panel .menu-hook-'+featuredPanel.currentIndex).addClass('active')
        $('#featured-panel .panel-hook-'+featuredPanel.currentIndex).addClass('active')

        $('#featured-panel .video-hook').off('ended', featuredPanel.videoEnd)
        const desktopVideo = $('#featured-panel .video-hook-'+featuredPanel.currentIndex).attr('data-desktop-video')
        const mobileVideo = $('#featured-panel .video-hook-'+featuredPanel.currentIndex).attr('data-mobile-video')
        const posterImage = $('#featured-panel .video-hook-'+featuredPanel.currentIndex).attr('data-poster-image')
        let mobilePosterImage = $('#featured-panel .video-hook-'+featuredPanel.currentIndex).attr('data-mobile-poster-image')
        if (!mobilePosterImage) {
            mobilePosterImage = posterImage
        }
        const elem = $('#featured-panel .video-hook-'+featuredPanel.currentIndex)[0]
        let oldSrc = elem.src
        let newSrc = elem.src
        let poster = posterImage
        //console.log('featuredPanel.mode3', featuredPanel.mode, featuredPanel.didFirstPosterSet)
        if (elem) {
            if (featuredPanel.mode === 'tiny') {
                newSrc = mobileVideo
                poster = mobilePosterImage
            } else {
                newSrc = desktopVideo
                poster = posterImage
            }
            if (oldSrc !== newSrc || featuredPanel.didFirstPosterSet === false) {
                elem.src = newSrc
                elem.poster = poster
                //console.log('enter', newSrc, poster)
                elem.load()
                elem.onloadeddata = function() {
                    elem.play();
                }
                featuredPanel.didFirstPosterSet = true
            }

            $('#featured-panel .video-hook-'+featuredPanel.currentIndex).on('ended', featuredPanel.videoEnd)
            if (oldSrc === newSrc) {
                elem.play()
            }
        }
        $('#featured-panel').addClass('loaded')


        //mobile detection
        if (featuredPanel.readyToEndFixed) {
            featuredPanel.reachedEnd = true
        } else {
            featuredPanel.reachedEnd = false
        }
        if (doResize) {
            featuredPanel.resize()
        }
    },

    setCookie: function (name, value, minutes) {
        var expires = "";
        if (minutes) {
            var date = new Date();
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}
export default featuredPanel
