import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import mobileNav from 'site/mobileNav'
import QuickThumbs from "./components/QuickThumbs";
const dayjs = require('dayjs')
const AdvancedFormat = require('dayjs/plugin/advancedFormat')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(AdvancedFormat)
const AWS = require('aws-sdk')

let showtimes = {
    currentDateSelected:null,
    lat:'',
    lon:'',
    isSearching:false,
    swiper: null,
    howManyToShow: 3,
    howManyToShowIncrememnt: 3,
    init: function() {
        if (typeof showtimesLinkageId !== "undefined" && showtimesLinkageId) {
            showtimes.askPermissionGetLocation()
            $(' .showtimes-search-button-hook').on('click', showtimes.getLatLonFromSearch)
            $('#showtimes-search-location').keydown(function(event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    showtimes.getLatLonFromSearch()
                }
            });
            //showtimes.oneTrustTest()
        }
    },
    getLatLonFromSearch() {
        //https://searchlight-studio-2023.dev.realpie.com/api/locationSearch.php?q=90031
        let searchTerm = $('#showtimes-search-location').val()
        searchTerm = searchTerm.replace(/\s+/g, '') //trim spaces
        if (searchTerm && !showtimes.isSearching ) {
            const apiUrl = `/actions/location-search?q=${searchTerm}`
            showtimes.setIsSearching(true)
            $.ajax({
                url: apiUrl,
                type: 'GET',
                dataType: 'json',
                crossDomain: true,
                success: function (response, textStatus) {
                    showtimes.setIsSearching(false)
                    if (response && response.success) {
                        //$('#showtimes-search-current').html('<p>Upcoming showtimes near ' + response[0]['display_name'] + ':</p>')
                        $('#showtimes-search-current').html('<p>Upcoming showtimes near search:</p>')
                        showtimes.lat = response.lat
                        showtimes.lon = response.lon
                        showtimes.getShowtimes()
                    } else {
                        $('#showtimes-search-current').html('<p>No showtimes find within your search.</p>')
                    }
                },
                error: function (jqXHR, textStatus) {
                    showtimes.setIsSearching(false)
                }
            });
        }

        /*
        //https://docs.locationiq.com/docs/search-forward-geocoding
        const apiKey = 'pk.1e0966bec02a6f7a1ab0c8371d12d2ce'
        let searchTerm = $('#showtimes-search-location').val()
        searchTerm = searchTerm.replace(/\s+/g, '') //trim spaces
        if (searchTerm && !showtimes.isSearching ) {
            searchTerm = searchTerm + ' USA'
            const apiUrl = `https://us1.locationiq.com/v1/search?key=${apiKey}&q=${searchTerm}&format=json`
            showtimes.setIsSearching(true)
            $.ajax({
                url: apiUrl,
                type: 'GET',
                dataType: 'json',
                crossDomain: true,
                success: function (response, textStatus) {
                    showtimes.setIsSearching(false)
                    if (response && response.length) {
                        //$('#showtimes-search-current').html('<p>Upcoming showtimes near ' + response[0]['display_name'] + ':</p>')
                        $('#showtimes-search-current').html('<p>Upcoming showtimes near search:</p>')
                        showtimes.lat = response[0].lat
                        showtimes.lon = response[0].lon
                        showtimes.getShowtimes()
                    } else {

                    }
                },
                error: function (jqXHR, textStatus) {
                    showtimes.setIsSearching(false)
                    //showtimes.askPermissionGetLocationError()
                }
            });
        }

         */
    },
    askPermissionGetLocation: function() {
        showtimes.setIsSearching(true)
        $('#showtimes-hook').html(showtimes.generateLoadingText())
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(showtimes.askPermissionGetLocationSuccess, showtimes.askPermissionGetLocationError, options);
        } else {
            showtimes.ipServiceFetch();
            //showtimes.getShowtimes()
        }
    },
    askPermissionGetLocationSuccess(position) {
        showtimes.lat = position.coords.latitude
        showtimes.lon = position.coords.longitude
        showtimes.getShowtimes()
    },

    ipServiceFetch() {
        //https://api.ipgeolocation.io/ipgeo?apiKey=99330ee6220e43269dfb813cf1c215dc&fields=geo
        const apiKey = 'fc26957c051047f1addfa9137ea48ec2'
        const apiUrl = `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&fields=geo`
        $.ajax({
            url: apiUrl,
            type: 'GET',
            dataType: 'json',
            crossDomain: true,
            success: function (response, textStatus) {
                showtimes.setIsSearching(false)
                $('#showtimes-search-current').html('<p>Upcoming showtimes near me:</p>')
                showtimes.lat = response.latitude
                showtimes.lon = response.longitude
                showtimes.getShowtimes()
            },
            error: function (jqXHR, textStatus) {
                showtimes.setIsSearching(false)
                $('#showtimes-hook').html('<p>Please search your location to get showtimes. You can also grant your browser permissions to access your location.</p>')
            }
        });

    },
    askPermissionGetLocationError() {
        showtimes.setIsSearching(false)
        $('#showtimes-search-current').html('')
        showtimes.ipServiceFetch()
    },

    getShowtimes() {
        showtimes.howManyToShow = 3
        let timezone
        try {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        } catch (e) {}
        const apiUrl = '/actions/get-showtimes?movieId=' + showtimesLinkageId + "&lat=" + showtimes.lat + "&lon=" + showtimes.lon + "&t=" + timezone
        $('#showtimes-hook').html(showtimes.generateLoadingText())

        $.ajax({
            url: apiUrl,
            type: 'GET',
            dataType: 'json',
            crossDomain: true,
            success: function (response, textStatus) {
                showtimes.setIsSearching(false)
                if (response && response.radius === 'noresults') {
                    $('#showtimes-search-current').html('')
                    $('#showtimes-hook').html('<p>No results found.  Please expand your search.</p>')
                } else {
                    showtimes.formatData(response)
                }
            },
            error: function (jqXHR, textStatus) {
                showtimes.setIsSearching(false)
                $('#showtimes-hook').html('<p>Showtimes could not be loaded.  Please try again later.</p>')
            }
        });
    },
    convertTime24to12: function (time24) {
        const [hours, minutes] = time24.split(':');
        const hours12 = ((hours % 12) || 12).toString();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        return hours12 + ':' + minutes + ' ' + amPm;
    },
    formatData: function (response) {
        showtimes.setIsSearching(false)
        $('#showtimes-search-current').html('<p>Upcoming showtimes near me:</p>')
        $('#showtimes-hook').html('<p>Done.</p>')

        let finalHtml = ``


        // MOBILE
        let thtmlDates = `<div class="date-boxes"><div class="date-boxes1"><div class="date-boxes2">`
        let ctr = 0
        for (let key in response) {
            thtmlDates += showtimes.formatDataDateBox(key, ctr)
            ctr++
        }
        thtmlDates += `</div></div></div>` //date-boxes
        finalHtml += `<div class="date-boxes-outer-mobile">${thtmlDates}</div>`

        // DESKTOP
        thtmlDates = ``
        thtmlDates += `<div class="strip-hider-trigger no-pad date-boxes"><div class="date-boxes1">`
            thtmlDates += `<div id="showtimes-swiper" class="swiper">`
                thtmlDates += `<div class="swiper-wrapper">`
                ctr = 0
                for (let key in response) {
                    thtmlDates += `<div class="swiper-slide">`
                    thtmlDates += showtimes.formatDataDateBox(key, ctr)
                    thtmlDates += `</div>` // swiper-slide
                    ctr++
                }
                thtmlDates += `</div>` // swiper-wrapper
            thtmlDates += `</div>` // swiper

            thtmlDates += `
                <div class="quick-thumbs-side prev quick-thumbs-side-prev">
                    <div class="btn-prev">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.148" height="55.435" viewBox="0 0 31.148 55.435">
                              <path id="angle-right" d="M104.548,101.117l-1.715,1.715L78.546,127.119l-1.715,1.715L73.4,125.4l1.715-1.715,22.572-22.572L75.115,78.546,73.4,76.831,76.831,73.4l1.715,1.715L102.833,99.4Z" transform="translate(-73.4 -73.4)" fill="#fff"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="quick-thumbs-side next quick-thumbs-side-next">
                    <div class="btn-next">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.148" height="55.435" viewBox="0 0 31.148 55.435">
                              <path id="angle-right" d="M104.548,101.117l-1.715,1.715L78.546,127.119l-1.715,1.715L73.4,125.4l1.715-1.715,22.572-22.572L75.115,78.546,73.4,76.831,76.831,73.4l1.715,1.715L102.833,99.4Z" transform="translate(-73.4 -73.4)" fill="#fff"/>
                            </svg>
                        </span>
                    </div>
                </div>
            `

        thtmlDates += `</div></div>` //date-boxes
        finalHtml += `<div id="date-boxes-outer-desktop" class="date-boxes-outer-desktop">${thtmlDates}</div>`


        let thtmlTheaterRows = `<div class="theater-rows">`
        ctr = 0
        let moreToLoad = false
        let distCounter = 0
        for (let key in response) {
            if (key !== 'radius') {
                let activeClass = (ctr === 0) ? 'active' : ''
                const obj = response[key]
                thtmlTheaterRows += `<div class="theater-rows-date theater-rows-date-${key} ${activeClass}">`
                for (let key2 in obj) {
                    const obj2 = obj[key2]

                    const first = obj2[0]
                    const dist = Number(first.distance).toFixed(1)
                    let distClass = ''
                    distClass = 'more'
                    //if (distCounter>2) {
                        //if (dist>50) { // 50 is radius to show in initial view - but we always at least show 3, even if some are further away than 50
                            //distClass = 'more'
                            //moreToLoad = true
                        //}
                    //}

                    thtmlTheaterRows += `<div data-ctr="${distCounter}" class="theater-row ${distClass}">`
                    thtmlTheaterRows += `<div class="theater-row-lr">
                            <div class="tr-l">
                                <h3>${first.name}</h3>
                            </div>
                            <div class="tr-l">
                                <div class="desc">
                                    ${first.address}, ${first.city} - ${dist} Miles
                                    <div class="times0">
                                        <div class="times">`
                        for (let i = 0; i < obj2.length; i++) {
                            let datetimePretty  = showtimes.formatDateTimeWithDayjs(obj2[i].date, obj2[i].time)
                            thtmlTheaterRows += `<div class="time">
                                                    ${showtimes.convertTime24to12(obj2[i].time)}
                                                    <div class="time-meta" style="display: none;">
                                                        <div class="time-meta-name">${obj2[i].name}</div>
                                                        <div class="time-meta-datetime">${datetimePretty}</div>
                                                        <div class="time-meta-theaterUrl">${first.theaterUrl}</div>
                                                        <div class="time-meta-links">${obj2[i].links}</div>
                                                    </div>
                                                </div>`
                        }
                        thtmlTheaterRows += `</div>
                                    </div>
                                </div>
                            </div>`
                    thtmlTheaterRows += `</div>`//theater-row-lr
                    thtmlTheaterRows += `</div>`//theater-row
                    distCounter++

                }//distCounter
                thtmlTheaterRows += `</div>`//theater-rows-date
                ctr++
            }
        }
        thtmlTheaterRows += `</div>`//theater-rows

        finalHtml += thtmlTheaterRows

        // load more
        /*
        if (moreToLoad) {
            finalHtml += `<div class="load-more">
            <button class="btn big load-more-hook" data-radius="${radius}">Load More</button>
        </div>`
        }
         */
        finalHtml += `<div class="load-more">
            <button class="btn big load-more-hook" >Load More</button>
        </div>`

        // end
        $('#showtimes-hook').html(finalHtml)

        if (response.length === 0) {
            $('#showtimes-hook').html('<p>No nearby showtimes found. Please broaden your search.</p><p>Note: Showtimes are currently for US locations.</p>')
            $('#showtimes-search-current').html('')
        }

        // listeners
        $('#showtimes-hook .date-boxes .box').on('click', showtimes.dateClick)
        $('#showtimes-hook .theater-rows .time').on('click', mobileNav.timeClick)
        $('#showtimes-hook .load-more-hook').on('click', showtimes.loadMore)

        showtimes.setIsSearching(false)

        showtimes.showMoreShowtimes('init')

        // showtimes swiper

        showtimes.swiper = new Swiper("#showtimes-swiper", {
            modules: [Navigation],
            init: false,
            loop: false,
            initialSlide: 0,
            spaceBetween: 20,
            slidesPerView: 'auto',
            slidesPerGroup: 5,
            //slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            touchMoveStopPropagation: true,
            mousewheelControl: false,
            navigation: {
                //nextEl: "#showtimes-swiper .swiper-button-next",
                //prevEl: "#showtimes-swiper .swiper-button-prev",
            },
        })
        showtimes.swiper.on('init', function(a) {
            showtimes.moreSlideHider(a)
            $('#date-boxes-outer-desktop .quick-thumbs-side-prev').on('click',showtimes.sidePrev)
            $('#date-boxes-outer-desktop .quick-thumbs-side-next').on('click',showtimes.sideNext)
        });
        showtimes.swiper.on('beforeInit', function(a) {

        });
        showtimes.swiper.on('slideChangeTransitionEnd', function(a) {

            showtimes.moreSlideHider(a)
        });
        showtimes.swiper.on('resize', function(a) {
            showtimes.moreSlideHider(a)
        });
        showtimes.swiper.init()
    },

    formatDataDateBox(key, ctr) {
        let ret = ``
        const dayjsDate = dayjs(key, { format: 'YYYYMMDD' })
        const monthDiv = dayjsDate.format('MMM')
        const dayDiv = dayjsDate.format('DD')
        const dayOfWeekDiv = dayjsDate.format('ddd')

        let activeClass = (ctr===0) ? 'active' : ''
        if (key !== 'radius') {
            ret = `
                <div class="box ${activeClass} box-${key}" data-date="${key}">
                    <div class="box2">
                        <div class="box3">
                            <div class="month">${monthDiv}</div>
                            <div class="day">${dayDiv}</div>
                            <div class="weekday">${dayOfWeekDiv}</div>
                        </div>
                    </div>
                </div>
            `
        } else if (key === 'radius') {
            //radius = response[key]
        }
        return ret
    },

    formatDateTimeWithDayjs: function (date, time) {
        // Combine the date and time strings
        const dateTimeString = date + time;

        // Use dayjs to parse and format the date-time string
        return dayjs(dateTimeString, "YYYYMMDDHH:mm").format("ddd, MMMM D @ h:mm A");
    },


    loadMore: function() {
        //let radius = Number($(this).attr('data-radius'))
        //radius += 25
        //$(this).remove()
        //showtimes.getShowtimes(radius)
        //theater-row-lr
        //$('#showtimes-hook .theater-row').removeClass('more')
        showtimes.showMoreShowtimes('more')
    },

    dateClick: function() {
        const date = $(this).attr('data-date')
        $('#showtimes-hook .theater-rows-date').removeClass('active')
        $('#showtimes-hook .theater-rows-date-'+date).addClass('active')
        $('#showtimes-hook .date-boxes .box').removeClass('active')
        $('#showtimes-hook .date-boxes .box-'+date).addClass('active')
        showtimes.showMoreShowtimes('init')
    },

    setIsSearching: function (val) {
        showtimes.isSearching = val
        if (val) {
            $('#showtimes-search-location').attr('disabled', 'disabled')
            $(' .showtimes-search-button-hook').attr('disabled', 'disabled')
            $('#showtimes-search-location').addClass('disabled')
            $(' .showtimes-search-button-hook').addClass('disabled')
        } else {
            $('#showtimes-search-location').removeAttr('disabled')
            $(' .showtimes-search-button-hook').removeAttr('disabled')
            $('#showtimes-search-location').removeClass('disabled')
            $(' .showtimes-search-button-hook').removeClass('disabled')
        }
    },

    showMoreShowtimes(mode='init') {
        // pass in init or all
        let showButton = false
        $('#showtimes-hook .theater-rows-date .theater-row').addClass('more') //hide all

        let numberShowtimes = $('#showtimes-hook .theater-rows-date.active .theater-row').length
        if (numberShowtimes && showtimes.howManyToShow < numberShowtimes) {
            showButton = true
            $('#showtimes-hook .theater-rows-date.active .theater-row').each(function(index, element) {
                if (index < showtimes.howManyToShow) {
                    $(this).removeClass('more')
                }
            })
            showtimes.howManyToShow += showtimes.howManyToShowIncrememnt
        } else {
            $('#showtimes-hook .theater-rows-date .theater-row').removeClass('more') //hide all
        }
        if (showButton) {
            $('#showtimes-hook .load-more').show()
        } else {
            $('#showtimes-hook .load-more').hide()
        }
    },

    generateLoadingText(label = 'Loading showtimes') {
        return `<div class="loading-text">
                    <div class="label">${label}</div>
                    <div class="ellipse-loading">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>`
    },


    moreSlideHider: function(a) {
        //console.log('a', a)
        let sel = '#date-boxes-outer-desktop'
        if (a.slides?.length > a.visibleSlides?.length) {
            $(sel).addClass('more-slides')
            $(sel + ' .quick-thumbs-side-next').addClass('more-slides')
            $(sel + ' .quick-thumbs-side-prev').addClass('more-slides')
        } else {
            $(sel).removeClass('more-slides')
            $(sel + ' .quick-thumbs-side-next').removeClass('more-slides')
            $(sel + ' .quick-thumbs-side-prev').removeClass('more-slides')
        }

        // show previous
        if (a.isEnd) {
            $(sel + ' .quick-thumbs-side-next').css('display','none')
            $(sel + ' .quick-thumbs-side-prev').css('display','flex')
        }
        // show next
        if (a.isBeginning) {
            $(sel + ' .quick-thumbs-side-next').css('display','flex')
            $(sel + ' .quick-thumbs-side-prev').css('display','none')
        }
        // in middle, show both
        if (!a.isEnd && !a.isBeginning) {
            $(sel + ' .quick-thumbs-side-next').css('display','flex')
            $(sel + ' .quick-thumbs-side-prev').css('display','flex')
        }
        // is both, aka no more slides, show none
        if (a.isEnd && a.isBeginning) {
            $(sel + ' .quick-thumbs-side-next').css('display','none')
            $(sel + ' .quick-thumbs-side-prev').css('display','none')
        }

        // update slides per group
        let slidesPerGroup = 1
        if (a && a?.visibleSlides?.length && a?.visibleSlides?.length > 2) {
            slidesPerGroup  = a.visibleSlides.length - 1
        }
        if (showtimes.swiper.params.slidesPerGroup !== slidesPerGroup) {
            showtimes.swiper.params.slidesPerGroup = slidesPerGroup
            showtimes.swiper.update()
        }

    },
    sidePrev: function() {
        let sel = '#date-boxes-outer-desktop'
        $(sel + ' .quick-thumbs-side-prev').removeClass('initial-hide')
        showtimes.swiper.slidePrev()
    },
    sideNext: function() {
        let sel = '#date-boxes-outer-desktop'
        $(sel + ' .quick-thumbs-side-next').removeClass('initial-hide')
        showtimes.swiper.slideNext()
    },
}
export default showtimes
