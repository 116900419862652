import QuickThumbs from 'site/components/QuickThumbs'
let quickThumbsPrep = {
    homeShows: null,
    homeTours: null,
    allThumbs: [],
    init: function () {
        $(window).on( "resize", quickThumbsPrep.resize);
        quickThumbsPrep.resize()
        quickThumbsPrep.setupSlides()
    }, //init
    setupSlides: function () {
        $('.quick-thumbs').each(function () {
            let id = $(this).attr('id')
            let dotsId = $(this).attr('data-dots-id')
            let quickThumbsMaxTotal = $(this).attr('data-max-total')
            let quickThumbsRandomInitial = ($(this).attr('data-random-initial'))
            let quickThumbsType = $(this).attr('data-type')
            let spaceBetween = Number($(this).attr('data-space-between'))
            let slideshow = new QuickThumbs('#'+id, quickThumbsRandomInitial, quickThumbsMaxTotal, quickThumbsType, spaceBetween)
            slideshow.init()


            // add to dotsId
            quickThumbsPrep.allThumbs[dotsId] = slideshow
        });
        $('.quick-thumbs-side.prev').on('click',quickThumbsPrep.sidePrev)
        $('.quick-thumbs-side.next').on('click',quickThumbsPrep.sideNext)
        quickThumbsPrep.resize()
        setTimeout(quickThumbsPrep.resize,500)
    },
    sidePrev: function() {
        const dotsId = $(this).attr('data-dots-id')
        $('.quick-thumbs-side-prev-'+dotsId).removeClass('initial-hide')
        quickThumbsPrep.allThumbs[dotsId].quickThumbsSwiper.slidePrev()

        $('.quick-thumbs-side-prev-'+dotsId).removeClass('initial-hide')

        $('#quick-thumbs-' + dotsId).addClass('clicked-once')
        $('#quick-thumbs-' + dotsId).parent().addClass('clicked-once')

    },
    sideNext: function() {
        const dotsId = $(this).attr('data-dots-id')
        $('.quick-thumbs-side-prev-'+dotsId).removeClass('initial-hide')
        quickThumbsPrep.allThumbs[dotsId].quickThumbsSwiper.slideNext()


        $('#quick-thumbs-' + dotsId).addClass('clicked-once')
        $('#quick-thumbs-' + dotsId).parent().addClass('clicked-once')
    },
    resize: function() {
        const docWidth = $(window).width()

        // in promo
        /*
        const specialPromo2Width = $('.specialPromo2').outerWidth(true) // width plus padding
        const rightWidth = $('.specialPromo2 .right').width()
        const extras = Math.ceil((docWidth - specialPromo2Width)/2)
        const final = rightWidth + extras
        $('.content-block.specialPromo .film-strip-outer').css('width',final+'px')

        // in film strip
        /*
        //const capperPaddedWidth = $('header.header .capper-padded').outerWidth(true) // width plus padding
        const capperPaddedWidth = $('header.header .capper-padded').width()
        const extrasFilmStrip = Math.ceil((docWidth - capperPaddedWidth)/2)
        //const finalFilmStrip = capperPaddedWidth + window.offsetLeft
        const finalFilmStrip = capperPaddedWidth + extrasFilmStrip
        $('.content-block.filmEntryStrip .film-strip-outer').css('width', finalFilmStrip+'px')
        $('.content-block.images .images-strip-outer').css('width', finalFilmStrip+'px')

         */
    }
}
export default quickThumbsPrep