import browser from 'browser-detect'
import siteCommon from 'site/site-common'
let site = {
    browser:browser(),
    init: function () {
        //add browser name
        $('body').addClass("browser_" + site.browser.name);

        //mobile stuff
        $('.ui-link').removeClass('ui-link');

        //disable img drag
        $('img').on('dragstart', function (event) {
            event.preventDefault();
        });

        if (site.browser.mobile) {
            $('body').addClass("device-mobile");
        } else {
            $('body').addClass("device-notmobile");
        }

        window.addEventListener('resize', site.resize)
        site.resize()

    },
    resize () {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        let ol = (window.innerWidth - 1920) /2
        if (ol <0) ol = 0
        document.documentElement.style.setProperty('--offsetLeft', `${ol}px`)
        window.offsetLeft = ol
        site.siteOrientationChange()
    },
    siteOrientationChange () {
        //if (isMobile) {

            setTimeout(function () {
                //t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
                //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
            }, 100)
        //}
    }
}
export default site
