import gsap, { Power4 } from 'gsap'
import Swiper from 'swiper'
import { Navigation, Pagination, Controller, Grid } from 'swiper/modules'

let mediaOverlay = {
    swiper:null,
    mediaOverlayIsOpen: false,
    init:function () {
        $('.mediaOverlay-hook').on('click', mediaOverlay.doMediaOverlayHandle);
        $('#mediaOverlay .overlay-x').on('click', mediaOverlay.doMediaOverlayClose);
        $(document).keyup(function(e) {
            if (mediaOverlay.mediaOverlayIsOpen) {
                if (e.keyCode === 27) { // escape key
                    mediaOverlay.doMediaOverlayClose();
                } else if (e.keyCode === 37) { // left arrow key
                    mediaOverlay.swiperPrev()
                } else if (e.keyCode === 39) { // right arrow key
                    mediaOverlay.swiperNext()
                }
            }
        })
    }, //init

    doMediaOverlayHandle: function() {
        let dataMetaId = $(this).attr('data-meta-id')
        let thtml = $('#swiper-popup-meta-'+dataMetaId).html()
        let initialSlide = $(this).attr('data-index')
        $('#swiper-super-outer').html(thtml)
        mediaOverlay.doMediaOverlayOpenReveal(initialSlide)
    },

    doMediaOverlayOpenReveal:function(initialSlide = 0) {
        mediaOverlay.mediaOverlayIsOpen = true
        mediaOverlay.swiper = new Swiper("#swiper-super-outer .swiper", {
            modules: [Navigation, Pagination],
            init: false,
            loop: true,
            initialSlide: initialSlide,
            spaceBetween: 20,
            slidesPerView: 1,
            slidesPerGroup: 1,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            touchMoveStopPropagation: true,
            mousewheelControl: false,
            navigation: {
                nextEl: "#swiper-super-outer .swiper-button-next",
                prevEl: "#swiper-super-outer .swiper-button-prev",
            },
            pagination: {
                enabled:true,
                clickable: true,
                el: "#media-dots .dots",
            },
        })
        mediaOverlay.swiper.init()
        gsap.fromTo($('#mediaOverlay' ), 1,
            {
                opacity:0,
            },
            {
                opacity:1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete:mediaOverlay.doMediaOverlayOpenReveal2,
            }
        )
        $('#mediaOverlay' ).show()
    },
    doMediaOverlayOpenReveal2:function() {

    },

    doMediaOverlayClose:function() {
        gsap.fromTo($('#mediaOverlay' ), 0.5,
            {
                opacity:1,
            },
            {
                opacity:0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete:mediaOverlay.doMediaOverlayClose2
            }
        );
        mediaOverlay.mediaOverlayIsOpen = false
    },
    doMediaOverlayClose2:function() {
        $('#mediaOverlay').hide()
    },

    swiperNext() {
        if (mediaOverlay.swiper) {
            mediaOverlay.swiper.slideNext()
        }
    },
    swiperPrev() {
        if (mediaOverlay.swiper) {
            mediaOverlay.swiper.slidePrev()
        }
    },

}
export default mediaOverlay;
