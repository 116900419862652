import AOS from 'aos'
import stickSite from "./site";
let siteAOS = {
    didInit:false,
    init: function () {
        if ( !siteAOS.didInit) {
            siteAOS.elementorAOSInit()
        }
        siteAOS.siteAOSReinit()
        siteAOS.didInit = true
    },
    siteAOSReinit() {
        AOS.init({
            offset: 0,
            duration: 500,
            once: true,
            //startEvent: 'load'
        })
        AOS.refreshHard()
    },
    elementorAOSInit: function () {
        $('.elementor-element').each(function () {
            $(this).attr('data-aos','fade-up')
        })
    },
    refreshAOS: function( ) {
        AOS.refresh()
    }
}
export default siteAOS