import mobileNav from 'site/mobileNav'
let films = {
    totalFilms:0,
    loadMoreIncrement: 40,
    totalFilmsShowingInit: 12, //2, 3, 4 = LCD
    totalFilmsShowing: null,
    filmsData: [],
    init: function () {
        //add browser name
        films.totalFilmsShowing = films.totalFilmsShowingInit
        films.totalFilms = $('.page-films .quick-thumbs-noswiper .swiper-wrapper .swiper-slide').length

        // Collect initial films data
        $('.page-films .quick-thumbs-noswiper .swiper-wrapper .swiper-slide').each(function () {
            films.filmsData.push({
                element: $(this),
                releaseDate: $(this).data('release-date'),
                title: $(this).data('title')
            })
        })

        $('.film-strip-load-more-hook').click(films.clickLoadMore)
        $('.hook-click-date').click(films.clickReleaseDate)
        $('.hook-click-az').click(films.clickAZ)

        films.clickReleaseDate()
    },
    clickLoadMore () {
        films.totalFilmsShowing += films.loadMoreIncrement
        films.showFilms()
    },
    clickAZ () {
        $('.hook-click-date').removeClass('selected')
        $('.hook-click-date').addClass('notselected')

        $('.hook-click-az').removeClass('notselected')
        $('.hook-click-az').addClass('selected')
        films.filmsData.sort((a, b) => a.title.localeCompare(b.title))
        films.updateFilmsOrder()
        films.totalFilmsShowing = films.totalFilmsShowingInit
        films.showFilms()
    },
    clickReleaseDate() {
        $('.hook-click-az').removeClass('selected')
        $('.hook-click-az').addClass('notselected')

        $('.hook-click-date').removeClass('notselected')
        $('.hook-click-date').addClass('selected')
        films.filmsData.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)); // Sorting by release date descending
        films.updateFilmsOrder()
        films.totalFilmsShowing = films.totalFilmsShowingInit
        films.showFilms()
    },
    updateFilmsOrder: function () {
        $('.page-films .quick-thumbs-noswiper .swiper-wrapper').empty();
        films.filmsData.forEach(film => {
            $('.page-films .quick-thumbs-noswiper .swiper-wrapper').append(film.element);
        });
    },
    showFilms() {
        let filmsobj = $('.page-films .quick-thumbs-noswiper .swiper-slide')
        let cnt = 0
        filmsobj.each(function (i) {
            if (i < films.totalFilmsShowing) {
                $(this).show()
                cnt ++
            } else {
                $(this).hide()
            }
        })

        if (cnt>=films.totalFilms) {
            $('.film-strip-load-more-hook').hide()
        } else {
            $('.film-strip-load-more-hook').show()
        }

        $('.ellipsis-outer').off('click', mobileNav.ellipsisClick);
        $('.ellipsis-outer').click(mobileNav.ellipsisClick);
    }
}
export default films
