import siteCommon from 'site/site-common'
import gsap, { Power3 } from 'gsap'
let mobileNav = {
    init:function () {
        var self = this;
        $('.header .profile').click(mobileNav.openProfile);
        $('#mobile-nav-hamburger .hamburger').click(mobileNav.doHamburerToggle);
        $('#mobile-nav-overlay .navitem').click(mobileNav.overlayHide);
        $('#mobile-nav-overlay .mobile-nav-expander').click(mobileNav.subNavToggle);
        $(document).keyup(function(e) {
            if (e.keyCode === 27) { // escape key
                mobileNav.overlayHide();
            }
        })
        mobileNav.overlayHide();

        $('.ellipsis-outer').click(mobileNav.ellipsisClick);
        $('.ellipsis-outer-label').click(mobileNav.ellipsisLabelClick);

        $('.header .search').click(mobileNav.openSearch);
        $('#search-bar .global-close').click(mobileNav.closeSearch);
        $('#search-form').on('submit',mobileNav.submitSearch);
        $('#search-submit').on('click',mobileNav.submitSearch);

        $(window).on( "resize", mobileNav.resize);
    },//init

    resize () {
        const h1Outer = $('#film-nav-overlay .mobile-content1').height()
        const h2Outer = $('#film-nav-overlay .mobile-content2').height()

        // better centering of content in scroll or no scroll situation
        if (h2Outer > h1Outer) {
            $('#film-nav-overlay .mobile-content1').addClass('block')
        } else {
            $('#film-nav-overlay .mobile-content1').removeClass('block')
        }
    },

    submitSearch: function (e) {
        let term = String($('#search-input').val())
        term = term.trim()
        if (term !== '') {
            if (!globalCookiesDisabledOptOut) {
                gtag('event', 'Action', {
                    'event_category': site_segment1,
                    'event_label': 'Search',
                    'value': term
                })
            }
            $('#search-form').submit()
        } else {
            e.preventDefault()
        }
    },

    openSearch: function () {
        siteCommon.scOpenTopOfPage()
        $('body').addClass('search-bar')
        setTimeout(function() {
            $('#search-input').focus();
        },200)

        mobileNav.overlayHide()
    },
    closeSearch: function () {
        $('body').removeClass('search-bar')
    },

    openProfile() {
        mobileNav.modeToAdd('mode-profile')
        mobileNav.overlayShow()
    },

    getLastSegment: function (inputString) {
        const segments = inputString.split('.');
        return segments[segments.length - 1];
    },

    extractDomain(url) {
        let domain = ''
        if (url) {
            // Remove the protocol (http/https)
            let withoutProtocol = url.replace(/^https?:\/\//, '')

            // Remove 'www' if present
            let withoutWww = withoutProtocol.replace(/^www\./, '')

            // Remove everything after the first "/"
            let domainWithTld = withoutWww.split('/')[0]

            // Remove the top-level domain
            domain = domainWithTld.split('.').slice(0, -1).join('.')

            // extract the last segment
            domain = mobileNav.getLastSegment(domain)
        }

        return domain
    },


    timeClick: function() {
        let metaH1 = $('.time-meta-name', this).html()
        let metaDatetime = $('.time-meta-datetime', this).html()
        let metaBugs = $('.time-meta-links', this).html()
        let metaTheaterUrl = $('.time-meta-theaterUrl', this).html()

        //if ($('#mobile-nav-hamburger .hamburger').hasClass('is-active')) {
        //    mobileNav.overlayHide();
        //} else {
            let metaBugsJson = JSON.parse(metaBugs);
            let bughtml = ``
            let widthRoot = 50
            let defaultBugIcon = $('#default-bug-icon').html();
            defaultBugIcon = defaultBugIcon.replace(/<img /g, `<img style="width:${widthRoot}px" `)
            if (metaBugsJson.length) {
                for (let i=0;i<metaBugsJson.length;i++) {
                    let bug = metaBugsJson[i]

                    let link = bug.value
                    if (!link) {
                        link = metaTheaterUrl
                    }

                    let bugTitle = String(bug.type).toLowerCase()

                    // if direct, change to a differe
                    if (bugTitle === 'direct') {
                        bugTitle = mobileNav.extractDomain(link)
                    }

                    //bug matchers
                    let foundBug
                    let map_array
                    if ($('body').hasClass('mode-film')) {
                        map_array = he_bug_map
                    } else {
                        map_array = showtimes_bug_map
                    }
                    if (bugTitle) {
                        foundBug = map_array.find(x => x.bugDirectSlugMap === bugTitle);
                    }
                    if (!foundBug) {
                        foundBug = map_array.find(x => x.slug === bug.type);
                    }


                    let bugIcon = ''
                    if (foundBug) {
                        let multiplier = (foundBug.multiplier) ? foundBug.multiplier : 1
                        let widthStyle = widthRoot * multiplier
                        bugTitle = foundBug.title
                        bugIcon = `<img style="width:${widthStyle}px" src="${foundBug.icon}"/>`
                    }

                    if (!bugIcon) {
                        bugIcon = defaultBugIcon
                    }

                    let linkHtml = ''
                    if (link) {
                        linkHtml = `<a href="${link}" target="_blank" onclick="theaterAnalytics(site_segment1, '` + bugTitle + `')">
                                <button class="btn">BUY NOW</button>
                            </a>`
                    }

                    if (!bugTitle) {
                        bughtml += mobileNav.noLinkFound(metaH1)
                    } else {
                        bughtml += `
                        <div class="bug">
                            <div class="bug-lr">
                                <div class="bug-icon">
                                    <div class="bug-icon2">
                                        ${bugIcon}
                                    </div>
                                </div>
                                <div class="bug-label">${bugTitle}</div>
                                <div class="bug-buy">
                                    ${linkHtml}
                                </div>
                            </div>
                        </div>
                        `
                    }
                }
            } else {
                bughtml += mobileNav.noLinkFound(metaH1)
            }


            // elipsis setup
            mobileNav.modeToAdd('mode-buy', metaDatetime)

            $('#buy-hook-h1').html(metaH1)
            $('#buy-hook-datetime').html(metaDatetime)
            $('#buy-hook-bugs').html(bughtml)

            mobileNav.overlayShow()
        //}
    },

    noLinkFound(name) {
        return `<div class="bug-none">
                    <div class="rte">
                        <p>No links found for ${name}.  Please visit this theater for more information.</p>
                    </div>
                </div>`
    },

    ellipsisLabelClick: function() {
        mobileNav.ellipsisClick2($(this).parent())
    },
    ellipsisClick: function() {
        mobileNav.ellipsisClick2($(this))
    },

    ellipsisClick2: function(t) {
        let metaH1 = $('.ellipsis-meta h2', t).html()
        let metaBugs = $('.ellipsis-meta .bugs', t).html()
        let metaLink = $('.ellipsis-meta', t).attr('data-link')
        //if ($('#mobile-nav-hamburger .hamburger').hasClass('is-active')) {
        //    mobileNav.overlayHide();
        //} else {
            // elipsis setup
        mobileNav.modeToAdd('mode-film')

        $('#ellipsis-hook-h1').html(metaH1)
        $('#ellipsis-hook-bugs').html(metaBugs)
        $('#ellipsis-link').attr('href',metaLink)

        mobileNav.overlayShow()
        //}
    },

    doHamburerToggle:function(){
        if ($(this).hasClass('is-active')) {
            mobileNav.overlayHide();
        } else {
            mobileNav.modeToAdd('mode-nav')
            mobileNav.overlayShow();
        }
    },//doHamburerToggle

    subNavToggle:function() {
        if ($(this).hasClass('open')) {
            mobileNav.subNavClose();
        } else {
            mobileNav.subNavOpen(this);
        }
    },
    subNavOpen:function(t) {
        mobileNav.subNavClose();
        $(t).addClass('open');
    },
    subNavClose:function() {
        $('#mobile-nav-overlay .header-2-nav-item-a').removeClass('open');
    },
    overlayShow:function() {
        const mode = siteCommon.scGetSiteMode()
        if (mode === 'tiny') {
            $('body').addClass('no-scroll')
        }

        mobileNav.closeSearch()
        //siteCommon.scOpenTopOfPage()
        $('#mobile-nav-hamburger .hamburger').addClass('is-active');
        $('#mobile-nav-overlay').removeClass('hidden');
        $('#mobile-nav-overlay-outer').removeClass('hidden');

        let tl = gsap.timeline({})
        tl.fromTo("#mobile-nav-overlay-outer", 1.4,
            {height:0},
            {height:'auto', ease:Power3.easeOut},
        );
        tl.staggerFromTo("#mobile-nav-overlay .mobile-reveal", 0.5,
            {opacity:0, y:-10 },
            {opacity:1, y:0, ease:Power3.easeOut},
            0.06,//stagger,
            "-=1.2"
        );

        mobileNav.resize()
    },
    overlayHide:function() {
        $('#mobile-nav-hamburger .hamburger').removeClass('is-active');
        $('#mobile-nav-overlay').addClass('hidden');
        $('#mobile-nav-overlay-outer').addClass('hidden');
        mobileNav.modeToAdd('')

        mobileNav.resize()
        $('body').removeClass('no-scroll')

    },

    modeToAdd (mode='', metaDatetime=null) {
        $('#mobile-nav-overlay-outer').removeClass('mode-profile')
        $('#mobile-nav-overlay-outer').removeClass('mode-nav')
        $('#mobile-nav-overlay-outer').removeClass('mode-buy')
        $('#mobile-nav-overlay-outer').removeClass('mode-film')
        $('#mobile-nav-hamburger').removeClass('mode-nav')
        $('#mobile-nav-hamburger').removeClass('mode-buy')
        $('#mobile-nav-hamburger').removeClass('mode-film')
        $('#mobile-nav-hamburger').removeClass('mode-profile')
        $('body').removeClass('mode-nav')
        $('body').removeClass('mode-buy')
        $('body').removeClass('mode-film')
        $('body').removeClass('mode-profile')

        if (mode !== '') {
            $('#mobile-nav-overlay-outer').addClass(mode)
            $('#mobile-nav-hamburger').addClass(mode)
            $('body').addClass(mode)
        }

        // analytics
        if (mode === 'mode-buy') {
            showtimesAnalytics(site_segment1, metaDatetime)
        }
    }

}; //obj
export default mobileNav
