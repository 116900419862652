import siteCommon from 'site/site-common'
let siteScroll = {
    lastScrollTop: 0,
    body: null,
    init:function () {
        siteScroll.body = $('body')
        ////////////////////////////
        $(window).on("scroll",siteScroll.scroll);
        $(window).on( "resize", siteScroll.scroll);

        //siteScroll.setHeaderPusher(false)
        siteScroll.scroll();

    },//init

    //////////////////////////////////////////////////////////////////////////////////////////
    scroll:function() {
        var scrollTop = $(window).scrollTop();
        var headershrink_h = 0;
        if (scrollTop>headershrink_h) {
            $('body').addClass('miniheader');
        } else {
            $('body').removeClass('miniheader');
        }
        //siteScroll.setHeaderPusher(true)

        let scrollCheckElem = document.querySelector(".scroll-check-trigger")
        if (scrollCheckElem) {
            if (window.scrollY >= scrollCheckElem.offsetTop - 175) { //X is buffer
                siteScroll.body.addClass('past-scroll-check');
                siteScroll.body.removeClass('above-scroll-check');
            } else {
                siteScroll.body.removeClass('past-scroll-check');
                siteScroll.body.addClass('above-scroll-check');
            }
        }

        if (scrollTop <=0 || scrollTop <= siteScroll.lastScrollTop) {
            // Scrolling Up
            siteScroll.body.addClass('scroll-dir-up')
            siteScroll.body.removeClass('scroll-dir-down')
        } else {
            siteScroll.body.removeClass('scroll-dir-up')
            siteScroll.body.addClass('scroll-dir-down')
        }
        if (scrollTop <=0 ) {
            siteScroll.body.addClass('scroll-top')
        } else {
            siteScroll.body.removeClass('scroll-top')
        }
        siteScroll.lastScrollTop = scrollTop;

        //$('header.header').addClass('first-load-done')
    },
}
export default siteScroll
